@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

body,
html,
body > div {
  transform: none !important;
}

* {
  box-sizing: border-box;
}

sub {
  font-size: 14px;
  top: 0.1em;
}

sup {
  font-size: 14px;
  top: -0.6em;
}

.fraction-divider {
  font-size: 24px;
}

/* REACT TOSTIFY */

.toastify-error-container {
  background-color: #ef4444 !important;
}

.toastify-error-container > button {
  color: white !important;
  opacity: 1;
}

.toastify-success-container {
  background-color: #059669 !important;
}

textarea:focus, input:focus {
  outline: none;
}

@media screen and (max-width: 480px) {
  .drawer-content-wrapper {
    width: 85% !important;
  }
}
